import svix from "../static/svg/svix.svg";
import oneDrive from "../static/svg/onedrive.svg";
import docusign from "../static/images/docusign.png";
import sharePoint from "../static/svg/sharepoint.svg";
import googleDrive from "../static/svg/google-drive.svg";
import salesforce from "../static/images/salesforce.png";
import dynamic365 from "../static/images/dynamics-365.png";

const METADATA_STEPS = {
    metaSteps: [{
        title: 'Create metadata',
        desc: 'Genetate metadata for each fields present in the template',
        moreDesc: `Genetate metadata for each fields present in the template by selecting field type, 
        and their corresponding options`
    },{
        title: 'Group input fields',
        desc: 'Group fields present in the template into multiple tabs',
        moreDesc: `Group fields present in the template into multiple tabs, 
        which will make the filling up data more interactive and understandable for end user`
    },{
        title: 'Output setup',
        desc: 'Select output format and select custom logo for public link',
        moreDesc: `Select output file format and choose custom logo for public links to showcase your brand image`
    }]
};

const FIELD_TYPES = {
    fileds: [{
        label: 'Normal text field',
        value: 'text',
        needOption: false
    },{
        label: 'Number field',
        value: 'number',
        needOption: false
    },{
        label: 'Email field',
        value: 'email',
        needOption: false
    },{
        label: 'Date field',
        value: 'date',
        needOption: true
    },{
        label: 'File upload field',
        value: 'file',
        needOption: true
    },{
        label: 'Rich text field',
        value: 'textarea',
        needOption: false
    },{
        label: 'Dropdown menu',
        value: 'dropdown',
        needOption: true
    },{
        label: 'Radio buttons',
        value: 'radio',
        needOption: true
    },{
        label: 'Checkboxes',
        value: 'checkbox',
        needOption: true
    }]
};

const FILE_FORMATS = {
    fileFormats: [{
        label: 'Image files',
        value: 'image/x-png,image/gif,image/jpeg,image/jpg'
    },{
        label: 'Document files',
        value: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    },{
        label: 'Excel files',
        value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    },{
        label: 'JSON files',
        value: 'application/json'
    },{
        label: 'XML files',
        value: 'text/xml'
    }]
};

const INTEGRATION_OPTIONS = {
    CLOUD_DATA_INTEGRATIONS: [{
        name: "Sales Force",
        icon: salesforce,
        path: 'salesforce'
    }, {
        name: "Dynamic 365",
        icon: dynamic365,
        path: 'dynamics365',
        input: [{
            name: "resource",
            label: "Resource URL",
            required: true
        }]
    }],
    CLOUD_STORAGE_INTEGRATIONS: [{
        name: "One Drive",
        icon: oneDrive,
        path: 'onedrive'
    }, {
        name: "Share Point",
        icon: sharePoint,
        path: 'sharepoint'
    }, {
        name: "Google Drive",
        icon: googleDrive,
        path: 'google-drive'
    }],
    DOCUMENT_SIGN_INTEGRATIONS: [{
        name: "DocuSign",
        icon: docusign,
        path: 'docusign'
    }],
    WEBHOOK_INTEGRATIONS: [{
        name: "Svix",
        icon: svix,
        path: 'svix'
    }]
};

const DB_VENDOR = [
    { value: 'oracle', label: 'Oracle' },
    { value: 'sqlserver', label: 'Microsoft SQL Server' },
    { value: 'mysql', label: 'MySQL' },
    { value: 'mongodb', label: 'MongoDB' },
    { value: 'postgres', label: 'Postgres' },
    { value: 'redis', label: 'Redis' },
];

const DEFAULT = {
    COMPANY: 'eDocGen',
    API_URL: '/',
    RESULT_SIZE: 15,
    TOKEN_KEY: 'EDTOKEN',
    USER_ROLE_KEY: 'EDROLE',
    PROFILE_IMG: "http://edocgen.s3-website-ap-southeast-1.amazonaws.com/static/media/profile-img.5cd71a8a.png",
    DB_VENDOR,
    ...METADATA_STEPS,
    ...FIELD_TYPES,
    ...FILE_FORMATS,
    ...INTEGRATION_OPTIONS
};

const prod = {
    ...DEFAULT,
    UI_DOMAIN: '/',
    API_URL: '/',
};

const dev = {
    ...DEFAULT,
    UI_DOMAIN: '/',
    API_URL: '/',
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
