import React, {useEffect, useState} from 'react';
import Head from "../../components/head";
import useRequest from "../../hooks/useRequest";
import Header from "../../components/dashboard/header";
import SideNav from "../../components/dashboard/side-navbar";
import InputData from "../../components/integration/inputData";

import {formUrlEncoded} from "../../utils";
import { config } from '../../components/constant';
import { commonFunction } from "../../components/common-functions";

const Integrations = (props) => {
    const {history} = props;
    const {
        COMPANY, CLOUD_STORAGE_INTEGRATIONS, DOCUMENT_SIGN_INTEGRATIONS, 
        WEBHOOK_INTEGRATIONS, UI_DOMAIN, CLOUD_DATA_INTEGRATIONS
    } = config;
    const [inputData, setInputData] = useState({});
    const [hasInput, setHasInput] = useState(false);
    const [inputFields, setInputFields] = useState([]);
    const [platformPath, setPlatformPath] = useState();
    const [availPlatform, setAvailPlatform] = useState([]);
    const [removePlatform, setRemovePlatform] = useState("");
    const [svixDashboardUrl, setSvixDashboardUrl] = useState("");

    const queryParam = formUrlEncoded({
        domain: `${UI_DOMAIN}integrate/${platformPath}`,
        ...inputData
    });
    const link = `api/v1/${platformPath}/auth-link?${queryParam}`;
    const { 
        loading:authLinkLoading, 
        data:authLinkData, 
        callAPI 
    } = useRequest(link, false);
    useEffect(() => {
        if(!authLinkLoading && authLinkData){
            window.location.replace(authLinkData.link);
        }
    }, [authLinkLoading, authLinkData, history]);

    const integration = `api/v1/integrations/`;
    const { 
        loading:inteLoading, 
        data:inteData,
        callAPI:inteCallAPI
    } = useRequest(integration);
    useEffect(() => {
        if(!inteLoading && inteData){
            setAvailPlatform(
                inteData.data.map(el => el.platform)
            );
        }
    }, [inteLoading, inteData]);

    useEffect(() => {
        if(platformPath && !hasInput){
            callAPI();
        }
    }, [platformPath, hasInput, callAPI]);

    const { 
        loading: deleteLoading, 
        data: deleteData, 
        error: deteteError,
        callAPI: callDeleteAPI
    } = useRequest(`${integration}?platform=${removePlatform}`, false, {}, "DELETE");

    useEffect(() => {
        if(!deleteLoading && deleteData){
            commonFunction.showAlert("Okay, Integration has been deleted successfully!");
            inteCallAPI();
        }

        if(deteteError){
            commonFunction.showAlert("Something went terribly wrong!", "error")
        }
    }, [deleteLoading, deleteData, deteteError, inteCallAPI]);


    const { 
        loading: webhookLoading, 
        data: webhookData
    } = useRequest("api/v1/webhook/dashboard");
    useEffect(() => {
        if(webhookData && !webhookLoading){
            const {dashboard} = webhookData;
            if(dashboard){
                setSvixDashboardUrl(dashboard);
            }
        }
    }, [webhookLoading, webhookData]);

    useEffect(() => {
        if(removePlatform){
            callDeleteAPI();
        }
    }, [removePlatform, callDeleteAPI]);

    return (
        <div>
            <Head customTitle={"Integrations"}/>
            <Header></Header>
            <SideNav index={9}></SideNav>
            <main className="dashboard-container integrations">
                <div className="main-container">
                    <div className="body">
                        <div className="body-section">
                            <h2 className="heading">Cloud Storage Integrations</h2>
                            <h4 className="sub-heading">
                                Connect {COMPANY} with the cloud storage you already know and love
                            </h4>
                            <div className="integration-options">
                            {
                                CLOUD_STORAGE_INTEGRATIONS.map((el, index) => {
                                    const {icon, name, path} = el;
                                    const btnCls = `btn ${availPlatform.indexOf(path) === -1 ? 'moral' : ''}`;
                                    return (
                                        <div className="options" key={index}>
                                            <img src={icon} alt="icon" className="icon"/>
                                            <div className="title">{name}</div>
                                            <div className="text">
                                                Authenticate to download or upload files on {name}
                                            </div>
                                            <button 
                                                className={btnCls}
                                                disabled={inteLoading || path === 'google-drive'}
                                                onClick={() => {
                                                    if(availPlatform.indexOf(path) > -1){
                                                        setRemovePlatform(path);
                                                        return;
                                                    }
                                                    setPlatformPath(path)
                                                }}
                                            >
                                                {availPlatform.indexOf(path) > -1 ? `Remove` : (
                                                    path === 'google-drive' ? `Coming soon...` : `Authenticate`
                                                )}
                                            </button>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                        <div className="body-section">
                            <h2 className="heading">CRM Integrations</h2>
                            <h4 className="sub-heading">
                                Connect {COMPANY} with the CRM you already know and love
                            </h4>
                            <div className="integration-options">
                            {
                                CLOUD_DATA_INTEGRATIONS.map((el, index) => {
                                    const {icon, name, path, input = []} = el;
                                    const btnCls = `btn ${availPlatform.indexOf(path) === -1 ? 'moral' : ''}`;
                                    return (
                                        <div className="options" key={index}>
                                            <img src={icon} alt="icon" className="icon"/>
                                            <div className="title">{name}</div>
                                            <div className="text">
                                                Authenticate to download or upload data on {name}
                                            </div>
                                            <button 
                                                className={btnCls}
                                                disabled={inteLoading || path === 'google-drive'}
                                                onClick={() => {
                                                    if(availPlatform.indexOf(path) > -1){
                                                        setRemovePlatform(path);
                                                        return;
                                                    }
                                                    setInputFields(input);
                                                    setHasInput(input && input.length > 0);
                                                    setPlatformPath(path);
                                                }}
                                            >
                                                {availPlatform.indexOf(path) > -1 ? `Remove` : `Authenticate`}
                                            </button>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                        <div className="body-section">
                            <h2 className="heading">Document Signature Integrations</h2>
                            <h4 className="sub-heading">
                                Connect {COMPANY} with the document sign provider you already know and love
                            </h4>
                            <div className="integration-options">
                            {
                                DOCUMENT_SIGN_INTEGRATIONS.map((el, index) => {
                                    const {icon, name, path} = el;
                                    const btnCls = `btn ${availPlatform.indexOf(path) === -1 ? 'moral' : ''}`;
                                    return (
                                        <div className="options" key={index}>
                                            <img src={icon} alt="icon" className="icon"/>
                                            <div className="title">{name}</div>
                                            <div className="text">
                                                Authenticate to sign document using {name}
                                            </div>
                                            <button 
                                                className={btnCls}
                                                disabled={inteLoading}
                                                onClick={() => {
                                                    if(availPlatform.indexOf(path) > -1){
                                                        setRemovePlatform(path);
                                                        return;
                                                    }
                                                    setPlatformPath(path)
                                                }}
                                            >
                                                {availPlatform.indexOf(path) > -1 ? `Remove` : `Authenticate`}
                                            </button>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                        <div className="body-section">
                            <h2 className="heading">Webhook Integrations</h2>
                            <h4 className="sub-heading">
                                Launch Svix Portal for webhook endpoints.
                            </h4>
                            <div className="integration-options">
                            {
                                WEBHOOK_INTEGRATIONS.map((el, index) => {
                                    const {icon, name, path} = el;
                                    const btnCls = `btn ${availPlatform.indexOf(path) === -1 ? 'moral' : ''}`;
                                    return (
                                        <div className="options" key={index}>
                                            <img src={icon} alt="icon" className="icon"/>
                                            <div className="title">{name}</div>
                                            <div className="text">
                                                Click to launch {name} webhooks portal.
                                            </div>
                                            <button 
                                                className={btnCls}
                                                disabled={inteLoading}
                                                onClick={() => {
                                                    console.log(svixDashboardUrl);
                                                    window.open(svixDashboardUrl);
                                                }}
                                            >
                                            Launch
                                            </button>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>

                        {hasInput && platformPath && 
                            <InputData
                                platform={platformPath}
                                inputFields={inputFields}
                                onSubmit={(formData) => {
                                    setInputData(formData);
                                    setHasInput(false);
                                    console.log("now call the api");
                                }}
                                close={() => {
                                    setPlatformPath(undefined);
                                    setHasInput(false);
                                }}
                            />
                        }
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Integrations;