import React, {useEffect, useState, Fragment} from 'react';
import useRequest from "../../../hooks/useRequest";
import ShowFileFolder from "./show-file-folders";
import { commonFunction } from "../../common-functions";

const OneDrive = (props) => {
    const {intl, callback, data, fileSelect, acceptedMime} = props;
    const {platform, data: selectedFolder} = data || {};

    const [loading, setLoading] = useState(false);
    const [folderData, setFolderData] = useState([]);
    const [folderPath, setFolderPath] = useState([...(
        selectedFolder && selectedFolder !== "/" ? selectedFolder.split('/') : []
    )]);
    const [showFolderPopup, setShowFolderPopup] = useState(false);
    
    const getFileUrl = `api/v1/onedrive/get-files?path=${
        folderPath.join('/') || "/"
    }`;
    const { 
        loading: fileLoading, 
        data: fileData,
        error: fileError,
        callAPI: getFileFolder,
        errorMessage: fileErrorMessage,
    } = useRequest(getFileUrl, false);
    useEffect(() => {
        if(!fileLoading && fileData){
            const {data} = fileData;
            setFolderData(data.value);
            setShowFolderPopup(true);
        }
    }, [fileLoading, fileData]);

    useEffect(() => {
        if(fileError && fileErrorMessage){
            commonFunction.showAlert(
                "Unable to fetch data, you might need to reauthenticate to allow access!",
                "error"
            );
        }
    }, [fileError, fileErrorMessage]);

    const openFolder = (el) => {
        setFolderPath(path => ([...path, el.name]));
        setTimeout(() => {
            getFileFolder();
        });
    };

    return (
        <Fragment>
            <div className={`platform${platform === 'onedrive' ? ' selected' : ''}`}>
                <div className="flex-container vertical-middle">
                    <div className="flexbox icon">
                        <img src={intl.icon} alt="icon"/>
                    </div>
                    <div className="flexbox name">
                        {intl.name}
                        {platform === 'onedrive' && 
                            <span
                                title={`Selected folder path: ${folderPath.join('/') || "/"}`}
                            >(Selected folder path: {folderPath.join('/') || "/"})</span>
                        }
                    </div>
                    <div className="flexbox button">
                        <button 
                            className="btn"
                            onClick={() => {
                                setLoading(true);
                                getFileFolder();
                            }}
                            disabled={loading}
                        >Select {fileSelect ? 'File' : 'Folder'}</button>
                    </div>
                </div>
            </div>
            {showFolderPopup && 
                <ShowFileFolder 
                    acceptedMime={acceptedMime}
                    fileSelect={fileSelect}
                    loading={fileLoading}
                    fileFolder={folderData}
                    folderPath={folderPath[folderPath.length - 1]}
                    open={openFolder}
                    select={(data) => {
                        if(data){
                            if(data.name){  
                                setFolderPath(path => {
                                    callback('onedrive', fileSelect ? 
                                        data["@microsoft.graph.downloadUrl"] : ([...path, data.name].join('/') || '/'),
                                        data
                                    );
                                    return [...path, data.name];
                                });
                                setShowFolderPopup(false);
                                setLoading(false);
                                return;
                            }

                            callback('onedrive', [...folderPath].join('/') || '/');
                            setShowFolderPopup(false);
                            setLoading(false);
                        }
                    }}
                    back={() => {
                        const path = [...folderPath];
                        path.pop();
                        setFolderPath(path);
                        setTimeout(() => {
                            getFileFolder();
                        });
                    }}
                    close={() => {
                        setShowFolderPopup(false);
                    }}
                    type='onedrive'
                />
            }
        </Fragment>
    );
};

export default OneDrive;