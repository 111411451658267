import React, { Fragment, useCallback, useEffect, useState } from "react";
import Select from 'react-select';
import DatabaseInput from "../database-input";
import DeleteConfirmModal from "../delete-confirm";
import CRMIntegrationData from "../crm-integration-data";

import { dataSourceOptions, getKeyValueBlock } from "../../configs/create-edit-automation.config";

const DataSources = (props) => {
  const {skey, data, callback} = props;
  
  const [formData, setFormData] = useState({});
  const [editIndex, setEditIndex] = useState(-1);
  const [headersData, setHeadersData] = useState([]);
  const [disableNext, setDisableNext] = useState(true);
  const [callCallback, setCallCallback] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if(callCallback){
      setCallCallback(false);
      const { sources = [], headers = [] } = data;
      const clonedHeaders = [...headers];
      const clonedSources = [...sources];
      if(editIndex !== -1){
        const {headers} = clonedSources[editIndex];
        headers.forEach(elm => {
          const index = clonedHeaders.findIndex(chead => chead === elm);
          if(index > -1){
            clonedHeaders.splice(index, 1);
          }
        });
        clonedSources[editIndex] = {...formData};
      }

      callback(skey, {
        headers: [...new Set([...clonedHeaders, ...headersData])],
        sources: editIndex !== -1 ? [...clonedSources] : [...clonedSources, {...formData}]
      });
      setFormData({});
      setEditIndex(-1);
      setHeadersData([]);
      setDisableNext(false);
    }
  }, [callCallback, editIndex, skey, data, callback, headersData, formData]);

  const deleteSource = useCallback((editIndex) => {
    setShowDeleteModal(false);
    const { sources = [], headers = [] } = data;
    const clonedSources = [...sources];

    const clonedHeaders = [...headers].filter(el => {
      const {headers} = clonedSources[editIndex];
      return !headers.includes(el);
    });
    clonedSources.splice(editIndex, 1);

    callback(skey, {
      headers: clonedHeaders,
      sources: clonedSources
    });
    setEditIndex(-1);
  }, [skey, data, callback]);

  const handleCallback = useCallback((dataKey, data) => {
    if(dataKey === "cancelEdit"){
      setFormData({});
      setEditIndex(-1);
      setHeadersData([]);
      return;
    }

    if(dataKey === "stepNumber"){
      setCallCallback(true);
      return;
    }

    const {headers: dataHeaders = [], ...otherData} = data;
    setHeadersData(headerData => ([...headerData, ...dataHeaders]));
    setFormData(formData => ({...formData, headers: dataHeaders, ...otherData}));
  }, []);

  const { sources = [] } = data;
  useEffect(() => {
    setDisableNext(Object.keys(formData).length > 0 || sources.length === 0);
  }, [formData, sources]);

  const {source} = formData;

  return (
    <Fragment>
      <div className="form-container">
        {
          sources.map((sourceData, index) => {
            const { source, crm, dbVendor } = sourceData;
            const keyValue = getKeyValueBlock(source, sourceData);
            return (
              <div className="group-creation" key={`${index}-${source}-${crm || dbVendor}`}>
                <div className="group form-group">
                  <div className="group-name">
                    <div className="flex-container vertical-middle">
                      <h4 className="flexbox meta-heading">Data source: {source === "crm" ? "CRM" : "Database"}</h4>
                      <div className="flexbox buttons">
                        <button className="btn small" onClick={() => {
                          setEditIndex(index);
                          setFormData({...sourceData});
                        }}>Edit</button>
                        <button className="btn small" onClick={() => {
                          setEditIndex(index);
                          setShowDeleteModal(true);
                        }}>Delete</button>
                      </div>
                    </div>
                  </div>
                  <div className="key-value columns">
                    {
                      keyValue.map(({key, value}, kvIndex) => (
                        <Fragment key={`${index}-${source}-${kvIndex}`}>
                          <div className="key">{key}</div>
                          <div className="value fixed-width">{value}</div>
                        </Fragment>
                      ))
                    }
                  </div>
                </div>
              </div>
            )
          })
        }
        <div className="meta-container">
          <div className="form-group has-field">
            <label htmlFor="data-source">Select data source <span>*</span></label>
            <Select 
              isClearable
              id="data-source" 
              name="data-source"
              className="input-select"
              options={dataSourceOptions}
              placeholder="Select data source for your automation"
              value={dataSourceOptions.filter(option => option.value === source)}
              onChange={(e) => {
                const value = e && e.value;
                if(!value){
                  setFormData({});
                  setEditIndex(-1);
                  return;
                }
                setFormData(formData => (
                  {...formData, source: value ? value : undefined}
                ));
              }}
            />
          </div>
          {source === "database" &&
            <DatabaseInput
              callback={handleCallback}
              data={formData}
              skey={source}
              isInsideBlock={true}
              isEdit={editIndex !== -1}
            />
          }
          {source === "crm" &&
            <CRMIntegrationData
              callback={handleCallback}
              data={formData}
              skey={source}
              isInsideBlock={true}
              isEdit={editIndex !== -1}
            />
          }
        </div>
      </div>
      <div className="footer text-right">
        <button className="btn" onClick={() => callback('stepNumber', 0)}>Back</button>
        <button
          disabled={disableNext}
          className="btn moral"
          onClick={() => callback('stepNumber', 2)}
        >Next Step</button>
      </div>
      {showDeleteModal && 
        <DeleteConfirmModal
          disableStatus={false}
          desc="The selected source will be deleted, you can add another source bby selecting data source."
          buttonText="Delete"
          isLoading={false}
          close={() => {
            setShowDeleteModal(false);
            setEditIndex(-1);
          }}
          doDelete={() => deleteSource(editIndex)}
        />
      }
    </Fragment>
  );
};

export default DataSources