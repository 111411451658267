import React, {useEffect, useState, Fragment} from 'react';
import useRequest from "../../../hooks/useRequest";
import ShowFileFolder from "./show-file-folders";
import { commonFunction } from "../../common-functions";

const Sharepoint = (props) => {
    const {intl, callback, data, fileSelect, acceptedMime} = props;
    const {platform, data: selectedDrive} = data || {};

    const [loading, setLoading] = useState(false);
    const [driveList, setDriveList] = useState([]);
    const [selectedDriveId, setSelectedDriveId] = useState("");
    const [showFolderPopup, setShowFolderPopup] = useState(false);
    
    const getDrivesUrl = `api/v1/sharepoint/drives`;
    const { 
        loading: driveLoading, 
        data: driveData,
        error: driveError,
        callAPI: getDriveList,
        errorMessage: driveErrorMessage
    } = useRequest(getDrivesUrl, false);
    useEffect(() => {
        if(!driveLoading && driveData){
            setDriveList(driveData.data.value.map(el => {
                if(el.driveType === 'documentLibrary'){
                    el.folder = true;
                }
                return el;
            }));
            setShowFolderPopup(true);
        }
    }, [driveLoading, driveData]);

    useEffect(() => {
        if(driveError && driveErrorMessage){
            commonFunction.showAlert(
                "Unable to fetch data, you might need to reauthenticate to allow access!",
                "error"
            );
        }
    }, [driveError, driveErrorMessage]);

    const getItemsInDrive = `api/v1/sharepoint/drives/${selectedDriveId}`;
    const { 
        loading: itemLoading, 
        error: itemError,
        data: itemData,
        callAPI: getItemAPI,
        errorMessage: itemErrorMessage
    } = useRequest(getItemsInDrive, false);
    useEffect(() => {
        if(!itemLoading && itemData){
            setDriveList(itemData.data.value);  
        }
    }, [itemLoading, itemData]);

    useEffect(() => {
        if(itemError && itemErrorMessage){
            commonFunction.showAlert(
                "Unable to fetch data, you might need to reauthenticate to allow access!",
                "error"
            );
        }
    }, [itemError, itemErrorMessage]);

    return (
        <Fragment>
            <div className={`platform${platform === 'sharepoint' ? ' selected' : ''}`}>
                <div className="flex-container vertical-middle">
                    <div className="flexbox icon">
                        <img src={intl.icon} alt="icon"/>
                    </div>
                    <div className="flexbox name">
                        {intl.name}
                        {platform === 'sharepoint' && 
                            <span
                                title={`Selected drive id: ${selectedDrive}`}
                            >(Selected drive id: {selectedDrive})</span>
                        }
                    </div>
                    <div className="flexbox button">
                        <button 
                            className="btn"
                            onClick={() => {
                                setLoading(true);
                                getDriveList();
                            }}
                            disabled={loading}
                        >Select Drive Folder</button>
                    </div>
                </div>
            </div>
            {showFolderPopup && 
                <ShowFileFolder 
                    acceptedMime={acceptedMime}
                    fileSelect={fileSelect}
                    loading={itemLoading || driveLoading}
                    fileFolder={driveList}
                    folderPath={selectedDriveId}
                    open={(data) => {
                        setSelectedDriveId(data.id);
                        setTimeout(() => {
                            getItemAPI();
                        });
                    }}
                    select={(data) => {
                        if(data && data.id){
                            callback('sharepoint', fileSelect ? data["@microsoft.graph.downloadUrl"] : data.id, data);
                            setShowFolderPopup(false);
                        }
                    }}
                    back={() => {
                        setSelectedDriveId("");
                        getDriveList();
                    }}
                    close={setShowFolderPopup}
                    type={!selectedDriveId ? "sharepoint" : ""}
                />
            }
        </Fragment>
    );
};

export default Sharepoint;