import { config } from "../components/constant";

export const formUrlEncoded = x => Object.keys(x).reduce((p, c) => {
  if(x[c] === undefined || x[c] === null){
    return p;
  }
  return `${p}${p ? '&' : ''}${c}=${encodeURI(x[c])}`;
}, '');

export const downloadTemplate = (nanobar, elid, filename) => {
  const token = localStorage.getItem(config.TOKEN_KEY);
  nanobar.current.classList.add('running');
  nanobar.current.setAttribute('data-state', 'running');

  fetch(`${config.API_URL}api/v1/document/download/${elid}?access_token=${token}`)
  .then(resp => resp.blob())
  .then(blob => {
    if(
      nanobar.current && nanobar.current.classList.contains('running') &&
      nanobar.current.getAttribute('data-state') === 'running'
    ){ 
      nanobar.current.setAttribute('data-state', 'paused');
    }

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  })
  .catch(() => alert('oh no!'));
};
