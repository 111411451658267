import { config } from "../constant";

export const steps = [{
  title: "Fillout the form fields",
  desc: "Fillout all the fields in the form to create automation",
  moreDesc: "Select a uploaded template and provide a name of the automation to proceed to next step"
}, {
  title: "Data source(s)",
  desc: "Add one/more data sources on which the automation will run",
  moreDesc: `You can add one or more data sources for your automation, when the automation 
  will run it will collect data from all the sources and combine them to create the document.`
}, {
  title: "Map fields",
  desc: "Map uploaded data fields with the fields of the template",
  moreDesc: `Map template fields with the fields present in the uploaded data, 
  by drag and drop the field to it's corresponding field.`
}, {
  title: "Schedule automation",
  desc: "Select the date and time to automate the document generation",
  moreDesc: `Set cronjob to generate document automatically and available 
  to output file on your email or on your ftp server`
}, {
  title: "Format and filename",
  desc: "Generate document by providing name of the file and the format",
  moreDesc: `Automate the document generation process by providing the generated 
  file name and the output file format.`
}, {
  title: "Distribution",
  desc: "Share the generated document with someone by email",
  moreDesc: `Automate the distribution of the generated document by selecting email 
  field from the database column.`
}];

export const printFormatValue = [{
  label: "PCL", 
  value: "pcl"
}, {
  label: "PostScript", 
  value: "post-script"
}];

export const dataSourceOptions = [
  { value: 'database', label: 'Database' },
  { value: 'crm', label: 'CRM' }
];

export const getKeyValueBlock = (source, data) => {
  const {CLOUD_DATA_INTEGRATIONS} = config;
  const {
    crm, hostUrl, dbVendor, dbUrl, dbPassword, dbQuery, dbLimit
  } = data;
  if(source === "crm"){
    const selectedCRM = CLOUD_DATA_INTEGRATIONS.find(el => el.path === crm);

    return [{
      key: "CRM name",
      value: selectedCRM ? selectedCRM.name : crm
    }, {
      key: "Host URL",
      value: hostUrl
    }];
  }

  const {DB_VENDOR} = config;
  const selectedVendor = DB_VENDOR.find(el => el.value === dbVendor);
  return [{
    key: "Database vendor",
    value: selectedVendor ? selectedVendor.label : dbVendor
  }, {
    key: "Connection URL",
    value: dbUrl
  }, {
    key: "Password",
    value: [...new Array(dbPassword.length)].fill("*").join("")
  }, {
    key: "Query",
    value: dbQuery
  }, {
    key: "Rows limit",
    value: dbLimit
  }]
};
