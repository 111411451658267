import React, {useEffect, useState} from 'react';

import Head from "../../components/head";
import {formUrlEncoded} from "../../utils";
import Logo from "../../static/images/logo.png";
import useRequest from "../../hooks/useRequest";
import { config } from '../../components/constant';
import { commonFunction } from "../../components/common-functions";

const Integrate = (props) => {
    const {UI_DOMAIN} = config;
    const {match, location, history} = props;
    const {platform} = match.params;
    const urlParam = new URLSearchParams(location.search);
    const code = urlParam.get("code");
    const errorCode = urlParam.get("error");
    const errorDesc = urlParam.get("error_description");
    
    const platformName = {
        onedrive: 'One Drive',
        sharepoint: 'Share Point',
        docusign: 'DocuSign',
        dynamics365: "Dynamic 365",
        salesforce: "Sales Force"
    };
    const [text, setText] = useState(
        `Integrating ${platformName[platform]}...`
    );

    const queryParam = formUrlEncoded({
        code,
        platform,
        domain: `${UI_DOMAIN}integrate/${platform}`
    });
    const link = `api/v1/integrations/save?${queryParam}`;
    const {loading, data, callAPI, error, errorMessage} = useRequest(link, false);
    useEffect(() => {
        if(!loading && data) {
            setText(
                `${platformName[platform]} has been successfully integrated, 
                you will be redirected to integration page, shortly!`
            );
            setTimeout(() => {
                history.push("/integrations/");
            }, 2000);
        }
    }, [history, loading, data, platformName, platform]);

    useEffect(() => {
        if(error && errorMessage){
            commonFunction.showAlert(errorMessage, 'error');
            return;
        }
    }, [error, errorMessage])

    useEffect(() => {
        callAPI();
    }, [callAPI]);

    useEffect(() => {
        if(!platform || !code){
            history.push('/');
            return;
        } 

        if(errorCode || errorDesc){
            setText(
                `${errorDesc}

                There is some error while integrating ${platformName[platform]}, 
                you will be redirected to integration page, shortly!`
            );
            setTimeout(() => {
                history.push("/integrations/");
            }, 5000);
            return;
        }
    }, [code, errorCode, errorDesc, history, platformName, platform]);

    return (
        <div>
            <Head customTitle={`${platform} Integration`}/>
            <section className="signup-holder">
                <div className="login-header text-center">
                    <img src={Logo} className="company-icon" alt="logo"/> 
                    {/* <h3></h3> */}
                    <h3 className="text-uppercase">{text}</h3>
                </div>
            </section>
        </div>
    )
};

export default Integrate;