import React, {useState, useEffect} from 'react';
import Select from 'react-select';

const EmailDistribution = (props) => {
  const {
    type, headers=[], skey, callback, tab, data = {}, required = false, disableFields = false
  } = props;

  const {emailColumn:pec = "", subject:psub = "", body:pb = ""} = data;
  const [body, setBody] = useState(pb);
  const [subject, setSubject] = useState(psub);
  const [emailColumn, setEmailColumn] = useState(pec);

  const emailColumnOption = headers.map(value => {
    return {value, label: value.replace(/_/g, ' ')}
  });

  useEffect(() => {
    callback(skey, {
      tab,
      subject, 
      emailColumn,
      body
    });
  }, [body, emailColumn, subject, tab, skey, callback]);

  return (
    <div className="tab-body">
      {type !== 'input' && 
        <div className="form-group has-field">
          <label htmlFor="email-column">Select email column{required && <span>*</span>}</label>
          <Select
            id="email-column"
            className="input-select"
            value={emailColumnOption.filter(option => option.value === emailColumn)}
            isSearchable={true}
            name="prefix"
            options={emailColumnOption}
            onChange={(e) => setEmailColumn(e.value)}
            placeholder="Select email column from uploaded data"
            isDisabled={disableFields}
          />
        </div>
      }

      <div className="form-group has-field">
        <label htmlFor="email-subject">Email subject{required && <span>*</span>}</label>
        <input
          type="text"
          id="email-subject"
          className="input-field"
          placeholder="Write down the subject of the email"
          autoComplete="off"
          onChange={(e) => setSubject(e.target.value)}
          required="required"
          value={subject}
          disabled={disableFields}
        />
      </div>

      <div className="form-group has-field">
        <label htmlFor="email-body">Email body{required && <span>*</span>}</label>
        <textarea
          type="text"
          id="email-body"
          className="input-field"
          placeholder="Write down the body of the email"
          autoComplete="off"
          onChange={(e) => setBody(e.target.value)}
          required="required"
          value={body}
          disabled={disableFields}
        />
      </div>
    </div>
  )
}

export default EmailDistribution;