import React, { Fragment, useEffect, useState } from 'react';

import Head from '../../components/head';
import Header from '../../components/dashboard/header';
import SideNav from '../../components/dashboard/side-navbar';
import DeleteConfirmModal from "../../components/dashboard/delete-confirm";

import useRequest from '../../hooks/useRequest';
import { useContextData } from "../../store/ContextData";
import { commonFunction } from '../../components/common-functions';

import refresh from "../../static/svg/ring-alt.svg"
import warning from "../../static/images/warning.png";
import { downloadTemplate } from '../../utils';

const { getIcon, getDateFormat, getFileSize, formatDate, showAlert } = commonFunction;

const TemplateVersion = (props) => {
  const {match} = props;
  const {params: {templateId}} = match;

  const { userRole, nanobar } = useContextData();
  const [buttonId, setButtonId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [allVersions, setAllVersion] = useState([]);
  const [templateInfo, setTemplateInfo] = useState({});
  const [disabledButton, setDisabledButton] = useState(false);
  const [selectedVersionId, setSelectedVersionId] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [versionDataLoading, setVersionDataLoading] = useState(true);

  const {data: templateData, loading: templateLoading} = useRequest(`api/v1/document/${templateId}`);
  useEffect(() => {
    if(templateData && !templateLoading){
      const {document} = templateData;
      if(document && document.length > 0){
        const [templateInfo] = document;
        setTemplateInfo(templateInfo);
        setIsLoading(false);
      }
    }
  }, [templateData, templateLoading]);
  
  const {
    data: versionData, loading: versionLoading, callAPI: callVersionAPI
  } = useRequest(`api/v1/document/${templateId}/version`);
  useEffect(() => {
    if(versionData && !versionLoading && templateInfo && !isLoading){
      const { document } = versionData;
      const allVersions = [{...templateInfo}, ...document];
      setAllVersion(allVersions);
      setVersionDataLoading(false);
    }
  }, [versionData, versionLoading, templateInfo, isLoading]);
  
  const {
    data: activateVersionData, loading: activateVersionLoading, callAPI: callActivateVersionAPI, 
    error: activateError, errorMessage: activateErrorMessage
  } = useRequest(`api/v1/document/${templateId}/version/${selectedVersionId}/setActive`, false, {}, "POST")
  useEffect(() => {
    if(selectedVersionId){
      if(activateVersionData && !activateVersionLoading){
        const currentVersion = allVersions.find(version => version._id === selectedVersionId);
        const {versionMetadata: curVerMetaData} = currentVersion;
        setAllVersion(versions => {
          return [...versions].map(version => {
            const {versionMetadata} = version;
            versionMetadata.active = (version._id === selectedVersionId);
            
            return {
              ...version,
              versionMetadata
            };
          });
        });
        setSelectedVersionId("");
        showAlert(`Version ${curVerMetaData.version} has been set as active.`);
      }
      
      if(activateError && activateErrorMessage){
        showAlert(activateErrorMessage, "error");
      }
    }
  }, [
    allVersions, selectedVersionId, activateVersionData, activateVersionLoading, activateError, activateErrorMessage
  ]);

  const {
    data: deleteData, loading: deleteLoading, callAPI: callDeleteAPI
  } = useRequest(`api/v1/document/${templateId}/version/${selectedVersionId}/`, false, {}, "DELETE");
  useEffect(() => {
    if(selectedVersionId && deleteData && !deleteLoading){
      const currentVersion = allVersions.find(version => version._id === selectedVersionId);
      const {versionMetadata: curVerMetaData} = currentVersion;
      setSelectedVersionId("");
      setVersionDataLoading(true);
      showAlert(`Version ${curVerMetaData.version} has been delete successfully.`);
      callVersionAPI();
    }
  }, [selectedVersionId, allVersions, deleteData, deleteLoading, callVersionAPI]);
  
  const { filename, addedAt, size, shared_with, isFlatten } = templateInfo;

  return (
    <div>
      <Head customTitle={"Template Version"}/>
      <Header></Header>
      <SideNav index={2}></SideNav>
      <main className="dashboard-container template">
        <div className="main-container">
          {isLoading && 
            <div className="public-form">
              <div className="loading-screen">
                <div className="flex-container vertical-middle full-height">
                  <div className="flexbox text-center">
                    <div className="loading">
                      <img src={refresh} className="loading" alt="loading"/>
                      <span id="loading" className="text-left">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {!isLoading && (
            <Fragment>
              <div className="template-details">
                <div className="icon-container">
                  <img src={getIcon(filename)} className="icon" alt="template type"/>
                </div>
                <div className="name-size-block">
                  <h1 className="name">{filename}</h1>
                  <div className="date-size">
                    <span className="text-uppercase">{getDateFormat(addedAt)}</span>
                    <span className="separator">-</span>
                    <span className="text-uppercase">{getFileSize(size)}</span>
                  </div>
                  <div className="key-value">
                    {userRole !== "3" && 
                      <Fragment>
                        <div className="key">Shared with</div>
                        <div className="value">
                        {
                          shared_with.reduce((data, key) => {
                            if(key === "2" && userRole !== 1){
                              return data;
                            }

                            data.push(key === "2" ? "Manager" : "User");
                            return data;
                          }, []).join(", ")
                        }
                        </div>
                      </Fragment>
                    }
                    <div className="key">Template is flatten</div>
                    <div className="value">{isFlatten ? "True" : "False"}</div>
                  </div>
                </div>
              </div>
              <h2 className="heading">All versions</h2>
              <h4 className="sub-heading">Find all available versions of the uploaded template.</h4>
              {!versionDataLoading &&
                <ul className="timeline-block">
                  {
                    allVersions.map(version => {
                      const { 
                        _id, versionMetadata: {active, version: versionNum}, 
                        birthtime, shared_with, isFlatten
                      } = version;

                      return (
                        <li className={active ? "current-version" : ""} key={_id}>
                          <div className="time text-uppercase">{formatDate(new Date(birthtime))}</div>
                          <span className="dot"></span>
                          <div className="title">
                            {versionNum === 0 ? "Initial version" : `Version ${versionNum}`}
                          </div>
                          <div className="key-value">
                            {userRole !== "3" && 
                              <Fragment>
                                <div className="key">Shared with</div>
                                <div className="value">
                                {
                                  shared_with.reduce((data, key) => {
                                    if(key === "2" && userRole !== 1){
                                      return data;
                                    }

                                    data.push(key === "2" ? "Manager" : "User");
                                    return data;
                                  }, []).join(", ")
                                }
                                </div>
                              </Fragment>
                            }
                            <div className="key">Template is flatten</div>
                            <div className="value">{isFlatten ? "True" : "False"}</div>
                          </div>

                          <div className={`buttons ${buttonId === _id ? "open" : ""}`}>
                            <button
                              className="btn bullet"
                              onClick={() => setButtonId(id => (id && id === _id) ? "" : _id)}
                            >
                              <span>&bull;&bull;&bull;</span>
                            </button>
                            <div className="actions">
                              <ul>
                                {!active && 
                                  <li onClick={() => {
                                    setButtonId("");
                                    setSelectedVersionId(_id);
                                    setTimeout(() => {
                                      callActivateVersionAPI();
                                    });
                                  }}>Mark as current</li>
                                }
                                <li onClick={() => {
                                  setButtonId("");
                                  downloadTemplate(nanobar, _id, `V${versionNum}-${filename}`);
                                }}>Download</li>
                                {versionNum !== 0 && 
                                  <li onClick={() => {
                                    setButtonId("");
                                    setShowDeleteConfirm(true);
                                    setSelectedVersionId(_id);
                                  }}>Delete...</li>
                                }
                              </ul>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  }
                </ul>
              }
              {!versionDataLoading && allVersions.length === 0 && 
                <div className="loading-container vertical">
                  <img src={warning} className="loading" alt="warning"/>
                  <span className="text-left">
                    No version present for this template. 
                    Please upload template with same name to create new version.
                  </span>
                </div>
              }
              {versionDataLoading && 
                <div className="loading-container loading">
                  <img src={refresh} className="loading" alt="loading"/>
                  <span id="loading" className="text-left">Versions loading...</span>
                </div>
              }
            </Fragment>
          )}
          {showDeleteConfirm && 
            <DeleteConfirmModal
              disabeStatus={disabledButton}
              desc={"This will delete the selected version of the template, once deleted\
              you will not be able to recover it."}
              buttonText="Delete version"
              disable={setDisabledButton}
              close={() => {
                setSelectedVersionId("");
                setShowDeleteConfirm(false);
              }}
              doDelete={() => {
                callDeleteAPI();
              }}
            />
          }
        </div>
      </main>
    </div>
  )
};

export default TemplateVersion;
