import React, {Component} from 'react';
import axios from 'axios';

import { config } from '../constant';
import FormInput from "./form-input";
import UploadDataFile from "./upload-data-file";
import Generation from "./generation";
import Distribution from "./distribution";
import MapFields from "./map-fields";
import DatabaseInput from "./database-input";
import CRMIntegrationData from "./crm-integration-data";

import dltBtn from "../../static/svg/delete.svg";
import xls from "../../static/images/xls.png";
import crm from "../../static/images/crm.png";
import form from "../../static/images/form.png";
import db from "../../static/images/db_sql.png";
import { commonFunction } from '../common-functions';

class DocumentGenerator extends Component{
    constructor(props){
        super(props);
        this.state = {
            tid: this.props.tid,
            selectedOption: this.props.type || undefined,
            subheadText: {
                input: 'Generate documents by filling out the fields of webform',
                upload: 'Generate documents by uploading JSON/XLSX/XML files.'
            },
            steps: {
                input: [{
                    title: 'Fillout the form fields',
                    desc: 'Fillout all the fields in the form to generate document',
                    moreDesc: `Fill the form, click on “${this.props.draft ? 'Update draft' : 'Save draft'}” to save half filled data or go to next step.`
                },{
                    title: 'Generation',
                    desc: 'Generate document by providing name of the file and the format',
                    moreDesc: 'Generate the document, download it or share it with people via email in next step.'
                }
                // ,{
                //     title: 'Distribution',
                //     desc: 'Share the generated document with someone by email',
                //     moreDesc: 'Distribute the generated document by putting email address manually or by selecting email field in the uploaded data.'
                // }
                ],
                upload: [{
                    title: 'Upload data file',
                    desc: 'You can upload .JSON/.XLSX/.XML data file.',
                    moreDesc: 'Upload supported data file and click on the next button to go to next step.'
                },{
                    title: 'Map fields',
                    desc: 'Map uploaded data fields with the fields of the template',
                    moreDesc: 'Map template fields with the fields present in the uploaded data, by drag and drop the field to it’s corresponding field.'
                },{
                    title: 'Generation',
                    desc: 'Generate document by providing name of the file and the format',
                    moreDesc: 'Generate the document, download it or share it with people via email in next step.'
                },{
                    title: 'Distribution',
                    desc: 'Share the generated document with someone by email',
                    moreDesc: 'Distribute the generated document by putting email address manually or by selecting email field in the uploaded data.'
                }],
                database: [{
                    title: 'Database connection details',
                    desc: 'Fillout all the database connection details to generate document',
                    moreDesc: 'Fill the database connection details and click on the connect button and then next button to go to next step.'
                },{
                    title: 'Map fields',
                    desc: 'Map uploaded data fields with the fields of the template',
                    moreDesc: 'Map template fields with the fields present in the database data, by drag and drop the field to it’s corresponding field.'
                },{
                    title: 'Generation',
                    desc: 'Generate document by providing name of the file and the format',
                    moreDesc: 'Generate the document, download it or share it with people via email in next step.'
                },{
                    title: 'Distribution',
                    desc: 'Share the generated document with someone by email',
                    moreDesc: 'Distribute the generated document by putting email address manually or by selecting email field in the uploaded data.'
                }],
                crm: [{
                    title: "CRM connection details",
                    desc: "Fillout all the fields to fetch data from integrated CRM",
                    moreDesc: 'Select the integrated CRM and fetch data from the CRM then next button to go to next step.'
                }, {
                    title: 'Map fields',
                    desc: 'Map uploaded data fields with the fields of the template',
                    moreDesc: 'Map template fields with the fields present in the CRM data, by drag and drop the field to it’s corresponding field.'
                },{
                    title: 'Generation',
                    desc: 'Generate document by providing name of the file and the format',
                    moreDesc: 'Generate the document, download it or share it with people via email in next step.'
                },{
                    title: 'Distribution',
                    desc: 'Share the generated document with someone by email',
                    moreDesc: 'Distribute the generated document by putting email address manually or by selecting email field in the uploaded data.'
                }]
            },
            fetched: false,
            stepNumber: 0,
            formData: this.props.fdata || {},
            crmData: {},
            uploadData: {},
            generationData: {},
            distributionData: {},
            mapData: {},
            markers: {},
            isFlatten: true,
            originalFormat: "",
            draft: this.props.draft || undefined,
            bulkDraft: false,
            folderName: this.props.folder || undefined,
            disableDraft: false
        };
        this.setInput = this.setInput.bind(this);
        this.generateDocFromData = this.generateDocFromData.bind(this);
        this.generateDocFromInput = this.generateDocFromInput.bind(this);
        this.createBulkDraft = this.createBulkDraft.bind(this);
    }

    setInput(key, data){
        var tempObject = {};
        tempObject[key] = data;
        this.setState(tempObject);
    }

    generateDocFromInput(){
        var tempObject = {
            markers: this.state.formData,
            documentId: this.state.tid,
            "async": true,
            format: this.state.generationData.format,
            outputFileName: `${this.state.generationData.name}.${this.state.generationData.format}`
        };

        this.props.submit('input', tempObject, `${this.state.generationData.name}.${this.state.generationData.format}`);
    }

    generateDocFromData(isPreview){
        var formData = new FormData(),
            validate;

        // Filter data
        formData.append('filterName', this.state.uploadData.filterName);
        formData.append('filterValue', this.state.uploadData.filterValue);

        // Database data
        if ( this.state.uploadData.dbVendor ) {
           formData.append('dbVendor', this.state.uploadData.dbVendor);
        }
        formData.append('dbUrl', this.state.uploadData.dbUrl);
        formData.append('dbPassword', this.state.uploadData.dbPassword);
        formData.append('dbQuery', this.state.uploadData.dbQuery);
        formData.append('dbLimit', this.state.uploadData.dbLimit);
        
        // CRM Data
        if(this.state.selectedOption === "crm"){
            formData.append("crm", this.state.uploadData.crm);
        }
        formData.append("reqOrigin", config.UI_DOMAIN);
        formData.append("hostUrl", this.state.uploadData.hostUrl);

        formData.append('documentId', this.state.tid);
        formData.append('markers', JSON.stringify(this.state.markers));
        formData.append('async', true);

        // Upload excel data if input file is uploaded
        if(this.state.uploadData.file){
           Array.from(this.state.uploadData.file).forEach(file => {
               formData.append('inputFile', file);
           });
        }
        formData.append('headersin', JSON.stringify(this.state.uploadData.headersin));
        formData.append('sheetindex', JSON.stringify(this.state.uploadData.sindex));
        formData.append('excelfields', this.state.uploadData.headers.join());

        // Mapping
        // console.log(this.state.mapData);
        validate = commonFunction.isObjectValid(this.state.mapData);
        formData.append('headerMappings', JSON.stringify(validate.flag === 0 ? {} : this.state.mapData));

        // Geeneration
        formData.append('format', this.state.generationData.format);
        formData.append('outputFileName', isPreview ? `${this.state.generationData.name}.${this.state.generationData.format}` : `${this.state.generationData.name}.zip`);
        formData.append('keyToFileName', this.state.generationData.prefix);
        formData.append('passwordColumn', this.state.generationData.passwordColumn);
        if(this.state.generationData.watermark){
            formData.append('watermark', this.state.generationData.watermark);
        }
        
        // Distribution
        if(!this.state.distributionData.tab || this.state.distributionData.tab === 'email'){
            formData.append('emailColumn', this.state.distributionData.emailColumn);
            formData.append('emailSubject', this.state.distributionData.subject);
            formData.append('emailBody', this.state.distributionData.body);
        }
        else if(this.state.distributionData.tab === 'cloud-storage'){
            formData.append('cloudstorage', true);
            formData.append('platform', this.state.distributionData.platform);
            formData.append('data', this.state.distributionData.data);
        }
        else if(this.state.distributionData.tab === 'sign-document'){
            formData.append('signdocument', true);
            formData.append('signSubject', this.state.distributionData.subject);
            formData.append('signers', JSON.stringify(this.state.distributionData.signers));
            formData.append('cc', JSON.stringify(this.state.distributionData.cc));
        }

        if(isPreview){
            formData.append('preview', true);
        }
        // console.log( this.state.uploadData );
        this.props.submit('upload', formData, this.state.generationData.name, isPreview, this.state.generationData.format);
    }

    generateDraft(e){
        if(e) e.preventDefault();
        this.setState({
            disableDraft: true
        }, () => {
            var formData = new FormData(),
                validate = commonFunction.isObjectValid(this.state.mapData);

            // Filter data
            formData.append('filterName', this.state.uploadData.filterName);
            formData.append('filterValue', this.state.uploadData.filterValue);

            if(this.state.selectedOption === "crm"){
                formData.append("crm", this.state.uploadData.crm);
            }
            formData.append("reqOrigin", config.UI_DOMAIN);
            formData.append("hostUrl", this.state.uploadData.hostUrl);

            formData.append('documentId', this.state.tid);
            formData.append('markers', JSON.stringify(this.state.markers));
            formData.append('async', true);

            // Upload excel data
            Array.from(this.state.uploadData.file).forEach(file => {
                formData.append('inputFile', file);
            });
            formData.append('headersin', JSON.stringify(this.state.uploadData.headersin));
            formData.append('sheetindex', JSON.stringify(this.state.uploadData.sindex));
            formData.append('excelfields', this.state.uploadData.headers.join());

            // Mapping
            console.log(this.state.mapData);
            validate = commonFunction.isObjectValid(this.state.mapData);
            formData.append('headerMappings', JSON.stringify(validate.flag === 0 ? {} : this.state.mapData));

            // Generation
            formData.append('format', this.state.generationData.format);
            formData.append('outputFileName', this.state.generationData.name);
            formData.append('keyToFileName', this.state.generationData.prefix);

            formData.append('tags', JSON.stringify([this.state.folderName]));
            this.props.draftf(formData);
        });
    }

    createBulkDraft(){
        this.setState({
            bulkDraft: true
        })
    }

    componentDidMount(){
        axios.get(`${config.API_URL}api/v1/document/markers/${this.state.tid}/`)
        .then(data => {  
            const {markers, isFlatten, originalFormat} = data.data;
            if(markers){
                this.setState({
                    isFlatten,
                    markers,
                    originalFormat,
                    fetched: true
                });
            }
        })
        .catch(() => {
            this.setState({
                isFlatten: true,
                markers: [],
                originalFormat: "",
                fetched: true
            });
        })
    }
    
    render(){
        return(
            <div>
                {this.state.fetched && <div className="full-page-overlay">
                    <div className="flex-container full-height vertical-middle">
                        {!this.state.selectedOption && 
                            <div className="template-uploader">
                                <div className="modal-body relative">
                                    <div className="heading">Generate documents</div>
                        
                                    <div className="close" onClick={() => this.props.callback('selectedTemplateId', undefined)}>
                                        <img src={dltBtn} alt="close"/>
                                    </div>
                                    <div className="sub-heading">Selected Template: <span>{this.props.tname}</span></div>
                                    <p className="desc">Generate documents by either uploading JSON/XLSX/XML files or by filling webform or by fetching data from external database.</p>

                                    <div className="option-selector" onClick={() => this.setInput('selectedOption', 'upload')}>
                                        <img src={xls} className="icon" alt="icon"/>
                                        <div className="text">
                                            <div className="title">Upload data</div>
                                            <div className="btn-desc">Generate documents by uploading JSON / XLSX / XML file</div>
                                        </div>
                                    </div>

                                    <div className="or-separator"></div>

                                    <div className="option-selector" onClick={() => this.setInput('selectedOption', 'input')}>
                                        <img src={form} className="icon" alt="icon"/>
                                        <div className="text">
                                            <div className="title">Form Input</div>
                                            <div className="btn-desc">Generate documents by filling out the fields of webform</div>
                                        </div>
                                    </div>

                                    <div className="or-separator"></div>

                                    <div className="option-selector" onClick={() => this.setInput('selectedOption', 'database')}>
                                        <img src={db} className="icon" alt="icon"/>
                                        <div className="text">
                                            <div className="title">Database Input</div>
                                            <div className="btn-desc">Generate documents by fetching data from external database.</div>
                                        </div>
                                    </div>

                                    <div className="or-separator"></div>

                                    <div className="option-selector" onClick={() => this.setInput('selectedOption', 'crm')}>
                                        <img src={crm} className="icon" alt="icon"/>
                                        <div className="text">
                                            <div className="title">CRM Input</div>
                                            <div className="btn-desc">Generate documents by fetching data from integrated CRM.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        
                        {(this.state.selectedOption === 'input' || this.state.selectedOption === 'upload' || this.state.selectedOption === 'database' || this.state.selectedOption === 'crm') &&
                        <div className="big-modal">
                            <div className="modal-body relative">
                                <div className="close" onClick={() => this.props.callback('selectedTemplateId', undefined)}>
                                    <img src={dltBtn} alt="close"/>
                                </div>
                                <div className="flex-container">
                                    <div className="flexbox step">
                                        <div className="heading">Document generation</div>
                                        <p className="desc">{this.state.subheadText[this.state.selectedOption]}</p>
                                        <ul className="steps">
                                            {
                                                this.state.steps[this.state.selectedOption].map((el, index) => (
                                                    <li key={index + el.title} className={`relative${(index === this.state.stepNumber ? ' active' : '')}${(this.state.stepNumber > index ? ' done' : '')}`}>
                                                        <div className="step-heading">{`Step ${index+1}. ${el.title}`}</div>
                                                        <div className="btn-desc">{el.desc}</div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    <div className="flexbox action relative">
                                        <div className="main-heading">{`Step ${this.state.stepNumber+1}. ${this.state.steps[this.state.selectedOption][this.state.stepNumber].title}`}</div>
                                        <div className="sub-heading">Selected Template: <span>{this.props.tname}</span></div>
                                        <div className="more-desc">{this.state.steps[this.state.selectedOption][this.state.stepNumber].moreDesc}</div>
                                        
                                        {this.state.selectedOption === 'input' && this.state.fetched &&
                                            <div>
                                                {this.state.stepNumber === 0 && <FormInput callback={this.setInput} skey="formData" tid={this.state.tid} markers={this.state.markers} data={this.state.formData} draft={this.state.draft} hidedraft={this.props.hidedraft} close={this.props.callback} folder={this.state.folderName}></FormInput>}
                                                {this.state.stepNumber === 1 &&
                                                    <Generation
                                                        callback={this.setInput}
                                                        skey="generationData"
                                                        markers={this.state.markers}
                                                        type={this.state.selectedOption}
                                                        data={this.state.generationData}
                                                        submit={this.generateDocFromInput}
                                                        originalFormat={this.state.originalFormat}
                                                        isFlatten={this.state.isFlatten}
                                                        tname={this.props.tname}
                                                    />
                                                }
                                                {/* {this.state.stepNumber === 2 && <Distribution callback={this.setInput} skey="distributionData" type={this.state.selectedOption} submit={this.generateDocFromInput}></Distribution>} */}
                                            </div>
                                        }

                                        {this.state.selectedOption === 'upload' && this.state.fetched &&
                                            <div>
                                                {this.state.stepNumber === 0 &&
                                                    <UploadDataFile
                                                        callback={this.setInput}
                                                        skey="uploadData"
                                                        data={this.state.uploadData}
                                                        tid={this.state.tid}
                                                    />
                                                }
                                                {this.state.stepNumber === 1 && 
                                                    <MapFields 
                                                        dataSource={this.state.selectedOption} 
                                                        callback={this.setInput} 
                                                        skey="mapData" 
                                                        headers={this.state.uploadData.headers} 
                                                        markers={this.state.markers} 
                                                        data={this.state.mapData}
                                                    />
                                                }
                                                {this.state.stepNumber === 2 && 
                                                    <Generation 
                                                        callback={this.setInput} 
                                                        skey="generationData" 
                                                        markers={this.state.markers} 
                                                        type={this.state.selectedOption} 
                                                        data={this.state.generationData} 
                                                        submit={this.generateDocFromData} 
                                                        draft={this.createBulkDraft}
                                                        originalFormat={this.state.originalFormat}
                                                        isFlatten={this.state.isFlatten}
                                                        tname={this.props.tname}
                                                    />
                                                }
                                                {this.state.stepNumber === 3 && 
                                                    <Distribution 
                                                        callback={this.setInput}
                                                        skey="distributionData"
                                                        type={this.state.selectedOption}
                                                        headers={this.state.uploadData.headers}
                                                        data={this.state.distributionData}
                                                        submit={this.generateDocFromData}
                                                        draft={this.createBulkDraft}
                                                    />
                                                }
                                            </div>
                                        }

                                        {this.state.selectedOption === 'database' && this.state.fetched &&
                                            <div>
                                                {this.state.stepNumber === 0 &&
                                                    <DatabaseInput
                                                        callback={this.setInput}
                                                        skey="uploadData"
                                                        data={this.state.uploadData}
                                                        tid={this.state.tid}
                                                    />
                                                }
                                                {this.state.stepNumber === 1 && 
                                                    <MapFields 
                                                        dataSource={this.state.selectedOption} 
                                                        callback={this.setInput} 
                                                        skey="mapData" 
                                                        headers={this.state.uploadData.headers} 
                                                        markers={this.state.markers} 
                                                        data={this.state.mapData}
                                                    />
                                                }
                                                {this.state.stepNumber === 2 &&
                                                    <Generation
                                                        callback={this.setInput}
                                                        skey="generationData"
                                                        markers={this.state.markers}
                                                        type={this.state.selectedOption}
                                                        data={this.state.generationData}
                                                        submit={this.generateDocFromData}
                                                        draft={this.createBulkDraft}
                                                        tname={this.props.tname}
                                                    />
                                                }
                                                {this.state.stepNumber === 3 && 
                                                    <Distribution
                                                        callback={this.setInput}
                                                        skey="distributionData"
                                                        type={this.state.selectedOption}
                                                        headers={this.state.uploadData.headers}
                                                        data={this.state.distributionData}
                                                        submit={this.generateDocFromData}
                                                        draft={this.createBulkDraft}
                                                    />
                                                }
                                            </div>
                                        }

                                        {this.state.selectedOption === "crm" && this.state.fetched && 
                                            <div>
                                                {this.state.stepNumber === 0 && 
                                                    <CRMIntegrationData
                                                        skey="uploadData"
                                                        data={this.state.uploadData}
                                                        callback={this.setInput}
                                                    />
                                                }
                                                {this.state.stepNumber === 1 && 
                                                    <MapFields 
                                                        dataSource={this.state.selectedOption} 
                                                        callback={this.setInput}
                                                        skey="mapData"
                                                        headers={this.state.uploadData.headers}
                                                        markers={this.state.markers}
                                                        data={this.state.mapData}
                                                    />
                                                }
                                                {this.state.stepNumber === 2 &&
                                                    <Generation
                                                        callback={this.setInput}
                                                        skey="generationData"
                                                        markers={this.state.markers}
                                                        type={this.state.selectedOption}
                                                        data={this.state.generationData}
                                                        submit={this.generateDocFromData}
                                                        draft={this.createBulkDraft}
                                                        tname={this.props.tname}
                                                    />
                                                }
                                                {this.state.stepNumber === 3 && 
                                                    <Distribution
                                                        callback={this.setInput}
                                                        skey="distributionData"
                                                        type={this.state.selectedOption}
                                                        headers={this.state.uploadData.headers}
                                                        data={this.state.distributionData}
                                                        submit={this.generateDocFromData}
                                                        draft={this.createBulkDraft}
                                                    />
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>}

                {this.state.bulkDraft && 
                    <div className="full-page-overlay">
                        <div className="flex-container full-height vertical-middle">
                            <div className="template-uploader">
                                <div className="modal-body relative">
                                    <div className="heading">Write down folder name </div>
                                    <div className="close" onClick={() => this.setState({bulkDraft: false})}>
                                        <img src={dltBtn} alt="close"/>
                                    </div>
                                    <p className="desc">Write down the name of the folder where drafts will be saved.</p>
                                    <form method="post" onSubmit={(e) => this.generateDraft(e)}>
                                        <div className="form-group has-field">
                                            <label htmlFor="draft-name">Folder name<span>*</span></label>
                                            <input type="text" id="draft-name" className="input-field" placeholder="Write down the name of the draft" autoComplete="off" onChange={(e) => this.setState({folderName: e.target.value})} required="required" value={this.state.folderName}/>
                                        </div>
                                        <div className="footer text-right">
                                            <button className="btn" type="button" onClick={() => this.setState({bulkDraft: false})}>Cancel</button>
                                            <button className="btn moral" disabled={!this.state.folderName || this.state.disableDraft} type="submit">Create draft</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default DocumentGenerator;
