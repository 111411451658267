import React, { Fragment, useCallback } from "react";
import EmailDistribution from "../email-distribution";

const AutomationDistribution = (props) => {
  const {
    headers, setStep, stepData, callback, skey, submit, isEdit
  } = props;
  const {printDocument = false, tab, subject, emailColumn, body} = stepData || {};

  const emailCallback = useCallback((tab, data) => {
    callback(tab, {...data, printDocument})
  }, [callback, printDocument]);

  return (
    <Fragment>
      <div className="form-container">
        <div className="form-group has-field">
          <label className="custom-input" data-type="checkbox">
            Do you want to print the generated document?
            <input 
              type="checkbox" 
              name="enable-print" 
              onChange={(e) => callback(skey, { 
                tab, subject, emailColumn, body, 
                printDocument: e.target.checked
              })} 
              checked={printDocument}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="or-separator"></div>
        <EmailDistribution
          type="db"
          tab="email"
          headers={headers}
          skey={skey}
          data={stepData}
          disableFields={printDocument}
          callback={emailCallback}
        />
      </div>
      <div className="footer text-right">
        <button 
          className="btn" 
          onClick={() => setStep(4)}
        >Back</button>
        <button 
          className="btn moral" 
          onClick={submit}
        >{isEdit ? "Edit" : "Create"} automation</button>
      </div>
    </Fragment>
  )
};

export default AutomationDistribution;