import React, { useContext, createContext, useState, useEffect } from "react";
import { config } from '../components/constant';
import useRequest from "../hooks/useRequest";

export const ContextData = createContext();
const ContextProvider = ({ nanobar, children }) => {
    const [userId, setUserId] = useState();
    const [userRole, setUserRole] = useState();
    const [email, setEmail] = useState('xx@xxxx.xxx');
    const [name, setName] = useState('xx xx');
    const [firstCharacter, setFirstCharacter] = useState('x');

    const hardLogout = () => {
        localStorage.removeItem(config.TOKEN_KEY);
        localStorage.removeItem(config.USER_ROLE_KEY);
        window.location.pathname = '/login/';
    };

    const docCountlink = `api/v1/document/generate/count`;
    const { loading:docLoading, data:docData, callAPI } = useRequest(
        docCountlink, false
    );
    
    const link = `api/v1/user`;
    const { loading, data, error } = useRequest(link);
    useEffect(() => {
        if(!loading){
            if(data){
                const {_id:id, role, name, fname, lname, email} = data.user;
                setUserId(id);
                setUserRole(role);
                setEmail(email);
                setName(`${fname} ${lname}`)
                setFirstCharacter(name[0]);
                if(process.env.NODE_ENV !== 'development'){
                    callAPI();
                }
            }
            
            if(error) hardLogout();
        }
    }, [loading, data, error, callAPI]);


    useEffect(() => {
        if(!docLoading && docData){
            const {count} = docData;
            window.onboardFlowSettings = {
                siteKey: "n65g733z", 
                user: { 
                    id: userId, 
                    customerID: userId, 
                    email,
                    imageUrl: config.PROFILE_IMG
                }, 
                customProperties: { 
                    total_documents_created: count 
                }
            };
        }
    }, [userId, email, docLoading, docData]);

    return <ContextData.Provider value={{
        userRole, email, name, firstCharacter, nanobar
    }}>{children}</ContextData.Provider>
};

export const useContextData = () => {
	return useContext(ContextData)
}

export default ContextProvider;