import React, {useEffect, useState, Fragment} from 'react';
import { config } from '../../constant';
import useRequest from "../../../hooks/useRequest";

import OneDrive from "./onedrive";
import Sharepoint from "./sharepoint";

const CloudStorage = (props) => {
    const {callback, data, fileSelect=false, acceptedMime} = props;
    const {CLOUD_STORAGE_INTEGRATIONS} = config;
    const [availPlatform, setAvailPlatform] = useState([]);
    const [hasCloudStoragePlatform, setHasCloudStoragePlatform] = useState(true);

    const integration = `api/v1/integrations/`;
    const { 
        loading: inteLoading, 
        data: inteData
    } = useRequest(integration);
    useEffect(() => {
        if(!inteLoading && inteData){
            const {data} = inteData;
            setAvailPlatform(data);
            setHasCloudStoragePlatform(data.some(
                el => CLOUD_STORAGE_INTEGRATIONS.find(csi => csi.path === el.platform)
            ));
        }
    }, [inteLoading, inteData, CLOUD_STORAGE_INTEGRATIONS]);

    return (
        <div className="tab-body cloud-storage">
            {
                availPlatform.map((el, index) => {
                    const {platform} = el;
                    const intl = CLOUD_STORAGE_INTEGRATIONS.find(intelm => intelm.path === platform);
                    return (
                        <Fragment key={platform + index}>
                            {platform === 'onedrive' && 
                                <OneDrive
                                    acceptedMime={acceptedMime}
                                    callback={callback}
                                    intl={intl}
                                    data={data}
                                    fileSelect={fileSelect}
                                />
                            }
                            {platform === 'sharepoint' && 
                                <Sharepoint
                                    acceptedMime={acceptedMime}
                                    callback={callback}
                                    intl={intl}
                                    data={data}
                                    fileSelect={fileSelect}
                                />
                            }
                        </Fragment>
                    )
                })
            }
            <div className="load-anchor text-center">
                {!inteLoading && !hasCloudStoragePlatform && 
                    <div className="loading vertical-middle">
                        <a href="/integrations/" className="btn-link center">Integrate Cloud storage</a>
                    </div>
                }
                {inteLoading && 
                    <div className="loading vertical-middle">
                        <span className="loading-text">Loading...</span>
                    </div>
                }
            </div>
        </div>
    );
};

export default CloudStorage;