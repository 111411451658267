import React, {Component} from 'react';
import axios from 'axios';
import Select from 'react-select';
import Alert from 'react-s-alert';

import Head from "../../components/head";
import { config } from '../../components/constant';
import Header from "../../components/dashboard/header";
import SideNav from "../../components/dashboard/side-navbar";
import { commonFunction } from '../../components/common-functions';
import DeleteConfirmModal from "../../components/dashboard/delete-confirm";

import add from "../../static/svg/add.svg";
import dltBtn from "../../static/svg/delete.svg";
import dlt from "../../static/images/delete.png";

class Users extends Component{
    constructor(props){
        super(props);
        this.state = {
            roleArray: ['Admin', 'Manager', 'User'],
            role: localStorage.getItem(config.USER_ROLE_KEY),
            currentUser: {},
            users: [],
            selectedUsers: [],
            createUser: false,
            formData: {
                name: '',
                email: '',
                password: '',
                role: '2',
                isValid: false,
                manager: ''
            }
        };
        this.deleteUser = this.deleteUser.bind(this);
    }

    setInput(key, data, isForm){
        var tempObject = {},
            tempArray = [],
            index = -1,
            flag = false;
        
        switch(key){
            case 'select':
                tempArray = [...this.state.selectedUsers];
                index = tempArray.indexOf(data);
                if(index === -1) tempArray.push(data);
                else tempArray.splice(index, 1)

                tempObject['selectedUsers'] = tempArray;
            break;

            case 'selectAll':
                tempArray = [...this.state.selectedUsers];
                if(tempArray.length !== this.state.users.length)
                    for(var i=0; i<this.state.users.length; i++){
                        index = tempArray.indexOf(this.state.users[i]._id);
                        if(index === -1) tempArray.push(this.state.users[i]._id);
                    }
                else tempArray = [];
                
                tempObject['selectedUsers'] = tempArray;
            break;

            default:
                if(isForm){
                    var formObject = {...this.state.formData},
                        manager = formObject['manager'];
                    delete formObject['isValid'];
                    formObject[key] = data.value ? data.value : data.target.value;
                    if(formObject.role !== '3') delete formObject['manager'];
                    data = commonFunction.isObjectValid(formObject);
                    formObject.isValid = (data.flag === data.key);
                    if(key !== "manager") formObject['manager'] = manager;
                    tempObject['formData'] = formObject;
                }
                else tempObject[key] = data;
            break;
        }

        this.setState(tempObject, () => {
            if(flag) this.getAllUsers();
        });
    }

    getAllUsers(){
        axios.get(`${config.API_URL}api/v1/getAllUsers/`)
        .then(res => {
            this.setState({
                users: [...this.state.users, ...res.data]
            });
        })
    }

    createUser(e){
        e.preventDefault();
        e.stopPropagation();

        if(this.state.currentUser.email){
            var formData = {
                name: this.state.formData.name,
                email: this.state.formData.email,
                password: this.state.formData.password,
                role: this.state.formData.role,
                creation_email: this.state.currentUser.email,
            };
            if(this.state.formData.role === '3') formData.manager = this.state.formData.manager;

            axios.post(`${config.API_URL}register/`, formData)
            .then(data => {
                Alert.success(`Nice! ${data.data.message}`, {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 5000
                });
                this.setState({
                    createUser: false,
                    formData: {
                        name: '',
                        email: '',
                        password: '',
                        role: '2',
                        isValid: false,
                        manager: ''
                    },
                    users: []
                }, () => {
                    this.getAllUsers();
                })
            })
            .catch(err => {
                if(err.response && err.response.data)
                    Alert.error(err.response.data.message, {
                        position: 'bottom-right',
                        effect: 'slide',
                        timeout: 5000
                    });
            });
        }
    }

    deleteUser(){
        if(this.state.selectedUsers.length > 0){
            this.setState({
                showDeleteConfirm: false
            }, () => {
                // var formData = new FormData();
                // formData.set('users', JSON.stringify(this.state.selectedUsers));

                axios.post(`${config.API_URL}api/v1/deleteOthers/`, {users: this.state.selectedUsers})
                .then((data) => {
                    Alert.success(`Nice! ${data.data.message}`, {
                        position: 'bottom-right',
                        effect: 'slide',
                        timeout: 5000
                    });
                    this.setState({
                        selectedUsers: [],
                        users: []
                    }, () => {
                        setTimeout(() => {
                            this.getAllUsers();
                        }, 500);  
                    })
                });
            });
        }
    }

    componentDidMount(){
        if(this.state.role !== '1') this.props.history.push('/template/');
        this.getAllUsers();
        axios.get(`${config.API_URL}getCurrentUser/`)
        .then(data => {
            this.setState({
                currentUser: data.data
            })
        })
    }

    render(){
        return (
            <div>
                <Head customTitle={"Users"}/>
                <Header></Header>
                <SideNav index={8}></SideNav>
                <main className="dashboard-container template">
                    <div className="main-container">
                        <div className="body">
                            <div className="flex-container">
                                <div className="flexbox two-third left">
                                    <div className="row-heading text-uppercase">
                                        <div className="flex-container vertical-middle">
                                            <div className="flexbox select-all">
                                                <div className="form-group">
                                                    <label className="custom-input" data-type="checkbox">
                                                        <input type="checkbox" name="select-all" value="true" onChange={() => this.setInput('selectAll')} checked={this.state.users.length > 0 && this.state.selectedUsers.length === this.state.users.length}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="flexbox name">
                                                <span>Name</span>
                                            </div>
                                            <div className="flexbox view-type text-right">
                                                Role
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-container">
                                    {
                                        this.state.users.map((el, index) => (
                                            <div key={index} className="data-row list">
                                                <div className="flex-container vertical-middle">
                                                    <div className="flexbox checkbox">
                                                        <div className="form-group">
                                                            <label className="custom-input" data-type="checkbox">
                                                                <input 
                                                                    type="checkbox" 
                                                                    name="select[]" 
                                                                    value={this._id} 
                                                                    checked={this.state.selectedUsers.indexOf(el.email) > -1} 
                                                                    onChange={() => this.setInput('select', el.email)}
                                                                />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="flexbox icon-name">
                                                        <div className="details text-left">
                                                            <span className="name" title={el.name}>{el.name}</span>
                                                            <span className="date-size">{el.email}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flexbox buttons">
                                                        <div className="flex-container column no-margin vertical-bottom">
                                                            <span className="text-uppercase">{this.state.roleArray[parseInt(el.role) - 1]}</span>
                                                            {el.manager && (
                                                                <div className="key-value">
                                                                    <div className="key">Manager</div>
                                                                    <div className="value">
                                                                        {el.manager.name}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    </div>
                                </div>
                                <div className="flexbox one-third right">
                                    <button type="button" className="btn with-image moral full" onClick={() => this.setInput('createUser', true)}>
                                        <img src={add} className="btn-img" alt="icon"/>
                                        Create a new user
                                    </button>
                                    <div className={this.state.selectedUsers.length > 0 ? "btn-link" : "btn-link disabled"} onClick={() => this.setInput('showDeleteConfirm', true)}>
                                        <img src={dlt} className="delete icon" alt="icon"/>
                                        Delete selected users
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {this.state.showDeleteConfirm &&
                        <DeleteConfirmModal
                            disabeStatus={false}
                            desc={"This will delete the user you have created"}
                            buttonText="Delete user"
                            close={() => {
                                this.setInput('showDeleteConfirm', false)
                            }}
                            doDelete={this.deleteUser}
                        />
                    }

                    {this.state.createUser && 
                        <div className="full-page-overlay">
                            <div className="flex-container full-height vertical-middle">
                                <div className="template-uploader">
                                    <div className="modal-body relative">
                                        <div className="heading">Create a new user</div>
                                        <div className="close" onClick={() => this.setInput('createUser', false)}>
                                            <img src={dltBtn} alt="close"/>
                                        </div>
                                        <p className="desc">Create a new user and set the role of the user</p>
                                        <form method="post" onSubmit={(e) => this.createUser(e)}>
                                            <div className="form-group has-field">
                                                <label htmlFor="name">Name<span>*</span></label>
                                                <input type="text" id="name" className="input-field" placeholder="Write down the name of the user" autoComplete="off" required value={this.state.formData.name} onChange={(e) => this.setInput('name', e, true)}/>
                                            </div>
                                            <div className="form-group has-field">
                                                <label htmlFor="email">Work email<span>*</span></label>
                                                <input type="email" id="email" className="input-field" placeholder="Write down the email of the user" autoComplete="off" required value={this.state.formData.email} onChange={(e) => this.setInput('email', e, true)}/>
                                            </div>
                                            <div className="form-group has-field">
                                                <label htmlFor="password">Password<span>*</span></label>
                                                <input type="password" id="password" className="input-field" placeholder="Write down the password of the user" autoComplete="off" required value={this.state.formData.password} onChange={(e) => this.setInput('password', e, true)}/>
                                            </div>
                                            <div className="form-group has-field">
                                                <label htmlFor="role">Role<span>*</span></label>
                                                <div className="flex-container">
                                                    <div className="flexbox">
                                                        <label className="custom-input" data-type="radio">
                                                            Manager
                                                            <input type="radio" name="role" value="2" checked={this.state.formData.role === '2'} onChange={(e) => this.setInput('role', e, true)}/>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                    <div className="flexbox">
                                                        <label className="custom-input" data-type="radio">
                                                            User
                                                            <input type="radio" name="role" value="3" checked={this.state.formData.role === '3'} onChange={(e) => this.setInput('role', e, true)}/>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.formData.role === "3" && 
                                                <div className="form-group has-field">
                                                    <label htmlFor="manager">Select manager<span>*</span></label>
                                                    <Select id="manager" className="input-select" name="manager" options={this.state.users.filter(el => el.role === "2").reduce((data, el) => data.concat({label: `${el.name} - (${el.email})`, value: el}), [])} placeholder="Select manager for the user" onChange={(e) => this.setInput('manager', e, true)}/>
                                                </div>
                                            }

                                            <div className="footer text-right">
                                                <button className="btn" onClick={() => this.setInput('createUser', false)}>Cancel</button>
                                                <button className="btn moral" type="submit" disabled={!this.state.formData.isValid}>Create user</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </main>
            </div>
        )
    }
}
export default Users;