import React, { Fragment, useCallback, useEffect, useState } from 'react';
import DatePicker from "react-datepicker";

import Head from "../../components/head";
import Header from "../../components/dashboard/header";
import SideNav from "../../components/dashboard/side-navbar";

import dayjs from "dayjs";
import Select from "react-select";
import useRequest from '../../hooks/useRequest';
import {commonFunction} from "../../components/common-functions";

import refresh from "../../static/svg/ring-alt.svg"
import {ReactComponent as User} from "../../static/images/user.svg";
import {ReactComponent as Image} from "../../static/images/image.svg";
import {ReactComponent as Failed} from "../../static/images/failed.svg";
import {ReactComponent as Success} from "../../static/images/success.svg";
import {ReactComponent as Started} from "../../static/images/started.svg";
import {ReactComponent as Manager} from "../../static/images/manager.svg";
import {ReactComponent as Template} from "../../static/images/template.svg";
import {ReactComponent as Document} from "../../static/images/document.svg";
import {ReactComponent as Requested} from "../../static/images/requested.svg";

require("../../static/css/dashboard.css");

const { formatDate } = commonFunction;

const Dashboard = () => {
  const today = new Date();
  const roleArray = ['Admin', 'Manager', 'User'];
  const initialData = {count: 0, date: "--"};
  const options = [{
    label: "All time",
    value: "all"
  }, {
    label: "Custom",
    value: "custom"
  }];

  const userTypes = roleArray.filter(role => role !== "Admin").map((role, index) => {
    return {
      label: role,
      value: (index + 2).toString()
    }
  });

  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const [recentEvents, setRecentEvents] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [imageData, setImageData] = useState(initialData);
  const [dashboardDetails, setDashboardDetails] = useState([]);
  const [documentData, setDocumentData] = useState(initialData);
  const [templateData, setTemplateData] = useState(initialData);
  const [selectedOption, setSelectedOption] = useState({...options[0]});
  const [selectedUserTypes, setSelectedUserTypes] = useState(userTypes);
  const [filteredDashboardData, setFilteredDashboardData] = useState([]);

  const queryParamToAdd = startDate ? `?startDate=${
    dayjs(startDate).format("YYYY-MM-DD")
  }&endDate=${dayjs(endDate).format("YYYY-MM-DD")}` : "";
  const {
    data: templateAPIData, loading: templateAPILoading, callAPI: callTemplateAPI
  } = useRequest("api/v1/dashboard/templates" + queryParamToAdd, false)
  useEffect(() => {
    if(templateAPIData && !templateAPILoading){
      const {count, lastUploaded} = templateAPIData;
      setTemplateData({
        count,
        date: lastUploaded ? formatDate(new Date(lastUploaded)) : "N/A"
      });
    }
  }, [templateAPIData, templateAPILoading]);

  const { 
    data: imageAPIData, loading: imageAPIDataLoading, callAPI: callImageAPI 
  } = useRequest("api/v1/dashboard/images" + queryParamToAdd, false);
  useEffect(() => {
    if(imageAPIData && !imageAPIDataLoading){
      const {count, lastUploaded} = imageAPIData;
      setImageData({
        count,
        date: lastUploaded ? formatDate(new Date(lastUploaded)) : "N/A"
      });
    }
  }, [imageAPIData, imageAPIDataLoading]);

  const {
    data: docAPIData, loading: docAPILoading, callAPI: callDocAPI 
  } = useRequest("api/v1/dashboard/documents" + queryParamToAdd, false)
  useEffect(() => {
    if(docAPIData && !docAPILoading){
      const { count, lastGenerated } = docAPIData;
      setDocumentData({
        count,
        date: lastGenerated ? formatDate(new Date(lastGenerated)) : "N/A"
      });
    }
  }, [docAPIData, docAPILoading]);

  const {
    data: detailsData, loading: detailsLoading, callAPI: callDetailsAPI
  } = useRequest("api/v1/dashboard/details" + queryParamToAdd, false);
  useEffect(() => {
    if(detailsData && !detailsLoading){
      const {data} = detailsData;
      setDashboardDetails(data);
    }
  }, [detailsData, detailsLoading]);

  const { 
    data: eventData, loading: eventDataLoading, callAPI: callEventAPI 
  } = useRequest("api/v1/events?startIndex=0&endIndex=10" + queryParamToAdd.replace('?', '&'), false);
  useEffect(() => {
    if(eventData && !eventDataLoading){
      const {events} = eventData;
      setRecentEvents(events);
    }
  }, [eventData, eventDataLoading]);

  const callAPI = useCallback(() => {
    setTimeout(() => {
      callTemplateAPI();
      callDocAPI();
      callImageAPI();
      callEventAPI();
      callDetailsAPI();
    });
  }, [callTemplateAPI, callDocAPI, callImageAPI, callEventAPI, callDetailsAPI]);

  useEffect(() => {
    const {value} = selectedOption;
    if(value === "all"){
      setStartDate(null);
      setEndDate(null);
      callAPI();
      return;
    }

    if(startDate && endDate){
      setOpenDropdown(false);
      callAPI();
    }
  }, [callAPI, selectedOption, startDate, endDate]);

  useEffect(() => {
    if(dashboardDetails && dashboardDetails.length > 0 && selectedUserTypes){
      const userRoleSet = selectedUserTypes.reduce((data, type) => {
        data.add(type.value);
        return data;
      }, new Set());

      const fileredData = dashboardDetails.filter(details => userRoleSet.has(details.role));
      setFilteredDashboardData(fileredData);
    }
  }, [dashboardDetails, selectedUserTypes]);

  const heroBlocks = [{
    id: "template",
    icon: Template,
    heading: "Template uploaded",
    count: templateData.count,
    lastUploaded: templateData.date,
    subText: "Last uploaded"
  }, {
    id: "document",
    icon: Document,
    heading: "Document generated",
    count: documentData.count,
    lastUploaded: documentData.date,
    subText: "Last generated"
  }, {
    id: "image",
    icon: Image,
    heading: "Images uploaded",
    count: imageData.count,
    lastUploaded: imageData.date,
    subText: "Last uploaded"
  }];

  return (
    <div>
      <Head customTitle={"Dashboard"}/>
      <Header></Header>
      <SideNav index={1}></SideNav>
      <main className="dashboard-container dashboard">
        <div className="main-container">
          <div className="flex-container searchbar">
            <div className="flexbox two-third"></div>
            <div className="flexbox one-third">
              <div className="sort relative">
                <div 
                  className="click-overlay" 
                  onClick={() => setOpenDropdown(status => !status)}
                ></div>
                <div className={openDropdown ? "sortby open" : "sortby"}>
                  Time range: 
                  <span className="select-text">
                    {(selectedOption.value !== "custom" || (
                      selectedOption.value === "custom" && (!startDate || !endDate)
                    )) && <span>{selectedOption.label}</span>}
                    {selectedOption.value === "custom" && startDate && endDate && 
                      <span>{`${formatDate(startDate)} - ${formatDate(endDate)}`}</span>
                    }
                  </span>
                  <span className="down-arrow"></span>
                  <ul className="sort-list">
                    {
                      options.map(option => {
                        const {label, value} = option;
                        return (
                          <li
                            key={value}
                            className={value === selectedOption.value ? 'selected' : ''}
                            onClick={() => {
                              setSelectedOption({...option});
                              if(value !== "custom"){
                                setOpenDropdown(status => !status);
                              }
                            }}
                          >
                            <label className="sort-label">{label}</label>
                            {value === "custom" && 
                              <div className="date-range flex-container">
                                <div className="flexbox one-half">
                                  <DatePicker
                                    selected={startDate}
                                    onFocus={() => setStartDate(null)}
                                    onSelect={(date) => setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={today}
                                    placeholderText="Start Date"
                                    dateFormat="yyyy-MM-dd"
                                  />
                                </div>
                                <div className="flexbox one-half">
                                  <DatePicker
                                    selected={endDate}
                                    onFocus={() => setEndDate(null)}
                                    onSelect={(date) => setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    maxDate={today}
                                    placeholderText="End Date"
                                    dateFormat="yyyy-MM-dd"
                                  />
                                </div>
                              </div>
                            }
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-container block-container">
            {
              heroBlocks.map(block => {
                const {id, icon: Icon, heading, count, lastUploaded, subText} = block;
                return (
                  <div key={id} className={`flexbox ${id} block with-flex one-third`}>
                    <div className="flexbox data">
                      <div className="description text-uppercase">{heading}</div>
                      <div className="main-number">{count}</div>
                      <div className="last-update">{`${subText}: ${lastUploaded}`}</div>
                    </div>
                    <div className="icon-container">
                      <Icon className={`icon ${id}`}/>
                    </div>
                  </div>
                );
              })
            }
          </div>
          <div className="flex-container block-container">
            <div className="flexbox block two-third details-block-container">
              <h2 className="heading">Template upload, document generation and image upload accross users</h2>
              {detailsLoading &&
                <div className="loading-container loading">
                  <img src={refresh} className="loading" alt="loading"/>
                  <span id="loading" className="text-left">Loading...</span>
                </div>
              }
              {!detailsLoading && 
                <Fragment>
                  <Select
                    id="user-type" 
                    isMulti={true}
                    className="input-select" 
                    isSearchable={true} 
                    placeholder="Select the user types" 
                    name="user-type" 
                    value={selectedUserTypes}
                    options={userTypes} 
                    onChange={(e) => {
                      setSelectedUserTypes(e ? e : []);
                    }} 
                  />
                  {
                    filteredDashboardData.map(details => {
                      const {_id, name, role, docCount, imageCount, templateCount} = details;
                      return (
                        <div key={_id} className="details-block">
                          <div className="name-role">
                            <h3>{name}</h3>
                            <div className="role">
                              {role === "2" && <Manager className="role-icon"/>}
                              {role === "3" && <User className="role-icon"/>}
                              <span>{roleArray[parseInt(role) - 1]}</span>
                            </div>
                          </div>
                          <div className="block-container">
                            {
                              heroBlocks.map(block => {
                                const {id, icon: Icon, heading} = block;
                                return (
                                  <div key={_id + id} className={`flexbox ${id} block with-flex one-third`}>
                                    <div className="icon-container">
                                      <Icon className={`icon ${id}`}/>
                                    </div>
                                    <div className="flexbox data">
                                      <div className="main-number">
                                        {id === "template" ? templateCount : (
                                          id === "document" ? docCount : imageCount
                                        )}
                                      </div>
                                      <div className="description text-uppercase">{heading}</div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </Fragment>
              }
            </div>
            <div className="flexbox block one-third">
              <h2 className="heading">LOGS</h2>
              {eventDataLoading &&
                <div className="loading-container loading">
                  <img src={refresh} className="loading" alt="loading"/>
                  <span id="loading" className="text-left">Loading...</span>
                </div>
              }
              {
                recentEvents.map(event => {
                  const {_id, output_name, type, time} = event;
                  return (
                    <div key={_id} className="logs">
                      <div className="log-icon">
                        {type === "DOCUMENT_GENERATION_STARTED" && <Started className="icon started"/>}
                        {type === "DOCUMENT_GENERATION_REQUESTED" && <Requested className="icon requested"/>}
                        {type === "DOCUMENT_GENERATION_SUCCESS" && <Success className="icon success"/>}
                        {(type === "DOCUMENT_GENERATION_FAILED" || type === "DOCUMENT_QUEUE_REQUEST_FAILED") && 
                          <Failed className="icon failed"/>
                        }
                      </div>
                      <div className="log-type no-padding">
                        <span className="name">{output_name}</span>
                        <div className="type">{type}</div>
                        <span className="time">{formatDate(new Date(time))}</span>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </main>
    </div>
  )
};

export default Dashboard;
