import axios from 'axios';
import {useState, useRef, useEffect} from 'react';
import { config } from '../components/constant.js';

// Axios GET method with cancel token
const GET = async(url, cancelToken, headers) => {
    return await axios.get(url, {
        headers,
        cancelToken
    });
};

// Axios PUT method with data and upload process callback
const PUT = async(url, data, onUploadProgress = undefined, headers) => {
    return onUploadProgress ? await axios.put(url, data, {
        headers,
        onUploadProgress
    }) : await axios.put(url, data, {
        headers
    });
};

// Axios POST method with data and upload process callback
const POST = async(url, data, onUploadProgress = undefined, headers) => {
    return onUploadProgress ? await axios.post(url, data, {
        headers,
        onUploadProgress
    }) : await axios.post(url, data, {
        headers
    });
};

// Axios DELETE method
const DELETE = async(url, data) => {
    return await axios({
        method: 'delete', url, data
    });
};

const CANCEL = () => axios.CancelToken.source();

const ISCANCEL = (err) => axios.isCancel(err);

// Based on the request method we are calling different function
// Cancel request method is applied to only GET request
// As for POST/PUT/DELETE method we are not cancelling the request
const getRequest = (requestType, url, onUploadProgress, headers) => {
    switch(requestType){
        case 'POST':
            return {
                request: (reqData) => {
                    return POST(url, reqData, onUploadProgress, headers)
                }
            };

        case 'PUT':
            return {
                request: (reqData) => {
                    return PUT(url, reqData, onUploadProgress, headers)
                }
            }

        case 'DELETE':
            return {
                request: (reqData) => {
                    return DELETE(url, reqData)
                }
            } 

        default:
            const source = CANCEL();
            return {
                request: () => {
                    return GET(url, source.token, headers)
                },
                source
            }
    };
};

/** 
// Utility function which takes 5 arguments
 - @param {string} url : REQUIRED : URL path to call
 - @param {object || FormData} requestData : OPTIONAL : Data which will be passed with the api
 - @param {string} requestType : OPTIONAL : GET/POST/PUT/DELETE : The type of request will be made
 - @param {boolean} autoGETCall : OPTIONAL : Decide whether to make automatic GET call or not
 - @param {function} onUploadProgress : OPTIONAL : Callback function which will 
   be triggered when upload is happening and provide upload percentage
*/
const useRequest = (
    url, 
    autoGETCall = true, 
    requestData = {}, 
    requestType = 'GET',
    headers = {},
    onUploadProgress = undefined
) => {
    // Defining some state varible
    // - The data which will be set for the request
    // - error flag variable
    // - loading status
    // - error message for the request
    const [data, setData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(autoGETCall);
    const [errorMessage, setErrorMessage] = useState(null);
    const APIFunctionRef = useRef();
    const HeaderRef = useRef();
    const DataRef = useRef();
    const FuncRef = useRef();
    const ComponentUnmountedRef = useRef(false);
    const callAPI = useRef(() => {
        if(APIFunctionRef.current === undefined || !APIFunctionRef.current) return;
        setData(null);
        setLoading(true);
        // After setting the promise in request variable
        // We are waiting for the promise to get resolved
        // Based on that we are setting the state data
        APIFunctionRef.current(DataRef.current)
        .then((res) => {
            // If the component is not unmounted
            // We are setting the res data
            // And setting the loading variable
            if(!ComponentUnmountedRef.current){
                setLoading(false);
                setData(res.data);
            }
        })
        .catch((error) => {
            // If the component is not unmounted
            // We are setting the loading variable
            if(!ComponentUnmountedRef.current){
                setLoading(false);
                // If it not an axios cancel
                // Set the error message and error variable
                if(!ISCANCEL(error)){
                    const errorMessage = (
                        error.response && 
                        error.response.data
                    ) ? error.response.data.message : error.message
                    setError(true);
                    setErrorMessage(errorMessage || error.message);
                }
            }
        });
    }).current;

    // Handling the formdata and non formdata
    const isFormData = (requestData instanceof FormData);
    if(isFormData) requestData = [...requestData.entries()];
    else requestData = JSON.stringify(requestData);
    useEffect(() => {
        if(isFormData){
            DataRef.current = requestData.reduce((data, el) => {
                data.set(el[0], el[1]);
                return data;
            }, new FormData());
        }
        else DataRef.current = JSON.parse(requestData);
    }, [isFormData, requestData]);

    useEffect(() => {
        HeaderRef.current = headers;
    }, [headers])
    
    useEffect(() => {
        FuncRef.current = onUploadProgress
    }, [onUploadProgress])

    url = `${config.API_URL}${url}`;
    useEffect(() => {
        ComponentUnmountedRef.current = false;

        // Resetting the state variable value for subsequent requests
        // Setting the initial value
        setData(null);
        setError(false);
        setLoading(autoGETCall);
        setErrorMessage(null);

        const {request, source} = getRequest(requestType, url, FuncRef.current, HeaderRef.current);
        APIFunctionRef.current = request;
        if(requestType === 'GET' && autoGETCall) callAPI();
        
        return () => {
            ComponentUnmountedRef.current = true;
            if(source && source.cancel instanceof Function) source.cancel("Component unmounted.");
        };
    }, [url, requestType, autoGETCall, callAPI]);

    return {
        data, 
        loading, 
        error, 
        errorMessage, 
        callAPI
    };
};

export default useRequest;
