import React, {useState, useEffect} from 'react';
import Select from 'react-select'
import useRequest from "../../hooks/useRequest";
import { commonFunction } from "../common-functions";
import { config } from '../constant';

const DatabaseInput = (props) => {
	const {callback, data, skey, showback = false, isInsideBlock = false, isEdit = false} = props;
	const {showAlert} = commonFunction;
	const {DB_VENDOR} = config;

	const {
		dbVendor:pdbVendor = "", dbUrl:pdbUrl = "", dbPassword:pdbPassword = "", 
		dbQuery:pdbQuery = "", dbLimit:pdbLimit = 1, headers:pheaders = []
	} = data;
	const [disabledNextStep, setDisabledNextStep] = useState(true);
	const [disabledConnect, setDisabledConnect] = useState(true);
	const [dbVendor, setDbVendor] = useState(pdbVendor);
	const [dbUrl, setDbUrl] = useState(pdbUrl);
	const [dbPassword, setDbPassword] = useState(pdbPassword);
	const [dbQuery, setDbQuery] = useState(pdbQuery);
	const [dbLimit, setDbLimit] = useState(pdbLimit);
	const [headers, setHeaders] = useState(pheaders);

	useEffect(() => {
		if(dbVendor && dbUrl && dbPassword && dbQuery){
			let urlRe = false;
			if(dbVendor === 'mongodb') {
				urlRe = /(.+?:\/\/)([^/]+?)@([^:]+?)(:\d+)?\/([^/]+)(\/.+)/.test(dbUrl);
			}
			else {
				urlRe = /(.+?)@(.+?)(:\d+)?\/(.+)/.test(dbUrl);
			}
			setDisabledConnect(!urlRe);
		}
		else setDisabledConnect(true);

		callback(skey, {
			dbVendor,
			dbUrl,
			dbPassword,
			dbQuery,
			dbLimit,
			headers,
			file: undefined,
			sheetArray: [],
			sindex: 1,
			headerin: 'row',
		});

		setDisabledNextStep(!(headers.length > 0));
	}, [dbVendor, dbUrl, dbPassword, dbQuery, dbLimit, headers, callback, skey]);

	useEffect(() => {
		if(dbLimit > 1000){
			setDbLimit(1000);
		}
	}, [dbLimit]);

	const link = `api/v1/document/getjsondbtags`;
	const { 
		loading, data:dbData, error, errorMessage, callAPI: getHeader
	} = useRequest(link, false, {
		dbVendor, dbUrl, dbPassword, dbQuery, 
		dbLimit: 1
	}, 'POST');
	useEffect(() => {
		if(!loading && dbData){
			setHeaders(dbData);
			showAlert(`Database connection successful. ${
				dbData.length > 0 ? 'Click on Next Step' : 'However zero rows received'
			}`)
		}
	}, [loading, dbData, showAlert]);

	useEffect(() => {
		if(error && errorMessage){
			const {message} = errorMessage;
			setHeaders([]);
			showAlert(message || 'Failed to get headers from database', 'error');
		}
	}, [error, errorMessage, showAlert]);

  return (
		<React.Fragment>
			<div className={`form-container${isInsideBlock ? " auto-height" : ""}`}>
				<div className="form-group has-field">
					<label htmlFor="dbVendor" >Database Vendor<span>*</span></label>
					<Select 
						id="filter" 
						className="input-select" 
						isSearchable={true} 
						placeholder="Select the vendor" 
						name="dbVendor" 
						value={DB_VENDOR.filter(option => option.value === dbVendor)}
						options={DB_VENDOR} 
						onChange={(e) => setDbVendor(e.value)} 
					/>
				</div>
				<div className="form-group has-field">
					<label htmlFor="dbUrl" >Connection URL<span>*</span></label>
					<input 
						type="text" 
						id="dbUrl" 
						className="input-field" 
						placeholder="[protocol://]username@hostname[:port]/dbname/tblname"
						onChange={(e) => setDbUrl(e.target.value)} 
						required="required" 
						value={dbUrl}
					/>
				</div>
				<div className="form-group has-field">
					<label htmlFor="dbPassword" >Password<span>*</span></label>
					<input 
						type="password" 
						id="dbPassword" 
						className="input-field" 
						placeholder="Enter the password" 
						onChange={(e) => setDbPassword(e.target.value)} 
						required="required" 
						value={dbPassword} 
					/>
				</div>
				<div className="form-group has-field">
					<label htmlFor="dbQuery">Select Query<span>*</span></label>
					<input 
						type="text" 
						id="dbQuery" 
						className="input-field" 
						placeholder="Enter the select SQL query" 
						onChange={(e) => setDbQuery(e.target.value)} 
						required="required" 
						value={dbQuery}
					/>
				</div>
				<div className="form-group has-field">
					<label htmlFor="dbLimit" >Limit Rows</label>
					<input 
						type="number" 
						min="1" 
						max="1000" 
						id="dbLimit" 
						className="input-field" 
						placeholder="Limit number of rows. ( max 1000, default 1000 )" 
						onChange={(e) => setDbLimit(e.target.value)} 
						value={dbLimit} 
					/>
				</div>
			</div>

			<div className="footer text-right">
				{showback &&
					<button 
						className="btn" 
						onClick={() => callback('stepNumber', 0)}
					>Back</button>
				}
				{isEdit && 
					<button onClick={() => callback('cancelEdit')} className="btn">Cancel</button>
				}
				<button 
					className="btn green" 
					id="databaseConnect" 
					disabled={disabledConnect} 
					onClick={() => getHeader()}
				>Connect</button>
				<button 
					className="btn moral" 
					id="nextDatabaseStep" 
					disabled={disabledNextStep} 
					onClick={() => callback('stepNumber', 1)}
				>{isInsideBlock ? `${isEdit ? "Edit" : "Add"} source` : "Next Step"}</button>
			</div>
    </React.Fragment>
  )
};

export default DatabaseInput;
